import React from 'react';
import CustomForm from './CustomForm';
import { Paper } from '@mui/material';

const ContactForm = props => {

    return (
        <Paper className='w-full flex items-end justify-center h-max sm:h-[600px] xl:h-[660px] flex-col' elevation={0}>
            <CustomForm />
        </Paper>
    );
};

export default ContactForm;