import React from 'react';
import { useSelector } from 'react-redux';
import { Gmail, LinkedIn } from '../assets/icons/Icons';
// import { Link } from 'react-scroll';
// import logo from '../assets/images/QUANTIS AI LABS.png';

const Footer = () => {
	const navState = useSelector((state) => state.navSlice.navState);

	return (
		<div className={`bg-[#2f2c2b] sm:bg-[#111]/80 flex justify-between items-center py-2 px-[15px] lg:px-[30px] ${navState !== 'Home' ? 'sm:fixed sm:bottom-0 sm:left-0 sm:right-0 sm:z-[99999]' : ''}`}>
			<div className='container !border-none !h-max !min-h-max flex flex-row sm:flex-row justify-between items-center'>
				{/* <Link className="nav-link" role="button" to="home" smooth={true} duration={500}>
					<img className={`nav-logo light my-2 sm:my-0 w-auto h-[1.2rem] sm:h-[1.4rem] z-[99999] cursor-pointer`} to={{pathname:"/"}} src={logo} alt="quantis" />
				</Link> */}
				<p className="footer-copyright sm:py-0 inline text-center text-[#fff] text-[14px]">© 2024</p>
				<p className="text-[10px] sm:text-xs text-[#fff] hidden sm:block sm:text-center">
					// We architect healthcare's “central nervous system”, attacking fragmentation to intercept emerging risks early and save thousands of lives yearly //
				</p>
				<ul className='flex list-none py-2 sm:py-0'>
					<li className='ml-4' ><a className='cursor-pointer' href="mailto:hello@quantis-labs.com"><Gmail className="fill-blue-500"  width={20} height={20} fill='#fff'/></a></li>
					<li className='ml-4' ><a className='cursor-pointer' target={'_blank'} rel="noreferrer" href="https://in.linkedin.com/company/quantislabs"><LinkedIn className="fill-blue-500" width={20} height={20} fill='#fff'/></a></li>
				</ul>
			</div>
		</div>
	)
}

export default Footer;