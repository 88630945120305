import React from 'react';
import CustomTabs from '../components/CustomTabs';
import overviewImage from '../assets/images/Medical_Diagnosis_AI.jpeg';
import individualsImage from '../assets/images/quantis-platform1.jpeg'
import physiciansImage from '../assets/images/quantis-platform2.jpeg'
import payersImage from '../assets/images/quantis-platform3.jpeg'

const OverviewTabContent = () => {
  return (
    <div className='pl-[10px] pr-[15px] lg:pl-[15px] lg:pr-[30px] py-[0rem] md:py-[1rem] lg:py-[0rem] w-full'>
      <h2 className='text-[1.2rem] font-semibold mb-4 lg:mb-3 leading-[1.3] lg:text-[1.4rem] lg:font-medium'>
        Own Your Health Story on Quantis Labs
      </h2>
      <p className='leading-normal mb-0 md:pb-3 text-[12px] sm:text-[14px]'>
        We are building the integrated lifetime health platform designed to put you in charge of your data. 
        Consolidate your records, tests, genome maps and wearables in one secure vault. Get AI-powered insights. Share safely on your terms. 
        The future of health begins with owning your story. 
      </p>
      <p className='leading-normal mb-0 md:pb-3 text-[12px] sm:text-[14px]'>
        The Next Generation Health Data Platform. Our stealth team of doctors, engineers and architects are designing the future:
      </p>
      <ul className='list-disc ml-[18px] mb-0 md:pb-3 text-[12px] sm:text-[14px]'>
        <li className='mb-1 leading-normal'>Fluid Lifetime Storage - Ever-expanding capacity for ever-expanding health science, ensured.</li>
        <li className='mb-1 leading-normal'>Military-Grade Security - Your sensitive health data secured to the highest standards.</li>
        <li className='mb-1 leading-normal'>Universal Interoperability - Integrating today's fragmented data sources and health tech innovations.</li>
        <li className='mb-1 leading-normal'>Custom Health Insights - AI assistant delivering tailored recommendations from your data.</li>
        <li className='mb-1 leading-normal'>Modular Design - Built to embed the medical innovations of tomorrow.</li>
      </ul>
      <p className='leading-normal mb-0 md:pb-3 text-[12px] sm:text-[14px]'>
        <span className='font-bold'>
          Are You Ready To Unlock Health Data's Full Potential?
        </span>
      </p>
    </div>
  )
}

const IndividualsTabContent = () => {
  return (
    <div className='px-[15px] lg:px-[30px] md:px-[15px] m-auto'>
      <h2 className='text-[1rem] font-semibold mb-4 lg:mb-1 leading-[1.3] lg:font-medium'>
        Empowerment Through Data Access and Control
      </h2>
      <ul className='list-disc ml-[18px] mb-0 md:pb-1 text-[12px] sm:text-[14px]'>
        <li className='mb-1 leading-normal'>Individuals gain complete ownership and control over their health data across providers and services</li>
        <li className='mb-1 leading-normal'>Intuitive access allows individuals to view, manage and share integrated records securely</li>
      </ul>
      <h2 className='text-[1rem] font-semibold mb-4 lg:mb-1 leading-[1.3] lg:font-medium'>
        Proactive and Personalised Care
      </h2>
      <ul className='list-disc ml-[18px] mb-0 md:pb-1 text-[12px] sm:text-[14px]'>
        <li className='mb-1 leading-normal'>Predictive analytics and risk models enable early health issue identification</li>
        <li className='mb-1 leading-normal'>Providers can offer precise, preventative care and lifestyle suggestions based on an individual's profile</li>
      </ul>
      <h2 className='text-[1rem] font-semibold mb-4 lg:mb-1 leading-[1.3] lg:font-medium'>
        Simplified User Experience
      </h2>
      <ul className='list-disc ml-[18px] mb-0 md:pb-1 text-[12px] sm:text-[14px]'>
        <li className='mb-1 leading-normal'>Consumer-inspired design with familiar interfaces makes platform accessible to all demographics</li>
        <li className='mb-1 leading-normal'>Mobile-first approach allows constant connectivity and real-time data syncing</li>
      </ul>
      <h2 className='text-[1rem] font-semibold mb-4 lg:mb-1 leading-[1.3] lg:font-medium'>
        Collaborative Healthcare Approach
      </h2>
      <ul className='list-disc ml-[18px] mb-0 md:pb-1 text-[12px] sm:text-[14px]'>
        <li className='mb-1 leading-normal'>Individuals can engage directly with providers around health goals and data-driven insights</li>
        <li className='mb-1 leading-normal'>Platform facilitates patient-provider collaboration for optimal, tailored care plans</li>
      </ul>
      <h2 className='text-[1rem] font-semibold mb-4 lg:mb-1 leading-[1.3] lg:font-medium'>
        Taking an active role in health and wellness
      </h2>
      <ul className='list-disc ml-[18px] mb-0 md:pb-1 text-[12px] sm:text-[14px]'>
        <li className='mb-1 leading-normal'>Dashboards, alerts and tracking help individuals monitor health proactively</li>
        <li className='mb-1 leading-normal'>Users are incentivised to own their health data and adopt preventative care behaviours</li>
      </ul>
      <p className='text-[12px] lg:font-bold lg:text-[14px] font-bold'>
        Overarchingly, Quantis AI Labs places healthcare control, accessibility and value firmly with the individual while enabling personalized and proactive medicine.
      </p>
    </div>
  )
}

const PhysiciansTabContent = () => {
  return (
    <div className='pl-[10px] pr-[15px] lg:pl-[15px] lg:pr-[30px] lg:p-0 md:pr-[15px] m-auto'>
      <p className='text-[12px] lg:font-normal lg:text-[14px] pb-2'>
        Quantis Labs' Lifetime Health Graph solution provides tremendous value to physicians in the following ways:
      </p>
      <ul className='list-disc ml-[18px] mb-0 md:pb-3 text-[12px] sm:text-[14px]'>
        <li className='mb-1 leading-normal'>
          <span className='font-bold'>Complete Patient History Access -</span>
          Physicians gain instant access to a patient's comprehensive records integrated across various systems onto one timeline view for faster and more accurate diagnoses.
        </li>
        <li className='mb-1 leading-normal'>
          <span className='font-bold'>Reduced Errors -</span>
          The data transparency from our graph reduces critical medical errors that happen due to scattered patient information locked in siloes. This improves care quality and outcomes.
        </li>
        <li className='mb-1 leading-normal'>
          <span className='font-bold'>Workflow Efficiency -</span>
          Our interoperability engine saves physicians' time spent struggling to gather records from multiple portals/databases which aids productivity.
        </li>
        <li className='mb-1 leading-normal'>
          <span className='font-bold'>Insights Assistance -</span>
          Our integrated platform analyses patient health trajectories using AI/ML techniques to provide helpful diagnostic clues and health optimisation recommendations to augment physician decisions.
        </li>
        <li className='mb-1 leading-normal'>
          <span className='font-bold'>Personalised Care -</span>
          Comprehensive data empowers physicians to tailor medical guidance/treatment plans optimised to an individual accounting for their unique markers and risk factors for more effective care.
        </li>
        <li className='mb-1 leading-normal'>
          <span className='font-bold'>Advanced Analytics -</span>
          Unified longitudinal data fuels advanced analytics at a population level to uncover trends, correlations and risk predictors that can help improve clinical guidelines.
        </li>
      </ul>
      <p className='text-[12px] lg:font-bold lg:text-[14px] font-bold'>
        In essence, by tackling data fragmentation we enhance physician decisions, productivity and performance - translating to better patient outcomes.
      </p>
    </div>
  )
}

const Payers = () => {
  return (
    <div className='px-[15px] lg:px-[30px] md:px-[15px] m-auto'>
      <h2 className='text-[1rem] font-semibold mb-4 lg:mb-0 leading-[1.3] lg:font-medium'>
        Enhanced Risk Assessment
      </h2>
      <ul className='list-disc ml-[18px] mb-0 md:pb-1 text-[12px] sm:text-[14px]'>
        <li className='mb-1 leading-normal'>Integrated health profiles allow more accurate risk evaluation and pricing models.</li>
        <li className='mb-1 leading-normal'>Predictive analytics flag potential issues early, enabling targeted interventions.</li>
      </ul>
      <h2 className='text-[1rem] font-semibold mb-4 lg:mb-0 leading-[1.3] lg:font-medium'>
        Streamlined Underwriting
      </h2>
      <ul className='list-disc ml-[18px] mb-0 md:pb-1 text-[12px] sm:text-[14px]'>
        <li className='mb-1 leading-normal'>Instant access to aggregated patient records reduces underwriting friction.</li>
        <li className='mb-1 leading-normal'>Continuous data monitoring enables dynamic policy pricing adjusted to real-time risk.</li>
      </ul>
      <h2 className='text-[1rem] font-semibold mb-4 lg:mb-0 leading-[1.3] lg:font-medium'>
        Improved Claim Processing
      </h2>
      <ul className='list-disc ml-[18px] mb-0 md:pb-1 text-[12px] sm:text-[14px]'>
        <li className='mb-1 leading-normal'>Complete patient timelines with diagnoses, medications, tests speed validations.</li>
        <li className='mb-1 leading-normal'>Automated claim handling and personalized policy management.</li>
      </ul>
      <h2 className='text-[1rem] font-semibold mb-4 lg:mb-0 leading-[1.3] lg:font-medium'>
        Reduced Costs
      </h2>
      <ul className='list-disc ml-[18px] mb-0 md:pb-1 text-[12px] sm:text-[14px]'>
        <li className='mb-1 leading-normal'>Preventative care lowers payouts through better outcomes.</li>
        <li className='mb-1 leading-normal'>Flagging at-risk patients cuts expenses via early non-claims interventions.</li>
      </ul>
      <h2 className='text-[1rem] font-semibold mb-4 lg:mb-0 leading-[1.3] lg:font-medium'>
        Personalised Offerings
      </h2>
      <ul className='list-disc ml-[18px] mb-0 md:pb-1 text-[12px] sm:text-[14px]'>
        <li className='mb-1 leading-normal'>Profiles of patients' conditions, histories and needs enable tailored products.</li>
        <li className='mb-1 leading-normal'>Segmented policy offerings that align with specific demographics.</li>
      </ul>
      <h2 className='text-[1rem] font-semibold mb-4 lg:mb-0 leading-[1.3] lg:font-medium'>
        New Revenue Streams
      </h2>
      <ul className='list-disc ml-[18px] mb-0 md:pb-1 text-[12px] sm:text-[14px]'>
        <li className='mb-1 leading-normal'>Platform data generates insights to inform additional value-added services.</li>
        <li className='mb-1 leading-normal'>Opportunities to upsell and cross-sell bespoke health management plans.</li>
      </ul>
      <p className='text-[12px] lg:font-bold lg:text-[14px] font-bold'>
        Overarchingly, Quantis AI Labs places healthcare control, accessibility and value firmly with the individual while enabling personalized and proactive medicine.
      </p>
    </div>
  )
}

const Platform = () => {

  var tabsData = {
    'Overview' : {
      'heading': 'This is heading one',
      'data': <OverviewTabContent />,
      'image': overviewImage,
    },
    'Individuals' : {
      'heading': 'This is heading two',
      'data': <IndividualsTabContent />,
      'image': individualsImage,
    },
    'Physicians' : {
      'heading': 'This is heading three',
      'data': <PhysiciansTabContent />,
      'image': physiciansImage,
    },
    'Payers' : {
      'heading': 'This is heading one',
      'data': <Payers />,
      'image': payersImage,
    }
  }

  return (
    <div id="platform" className='mainSection min-h-[60vh] flex flex-col lg:flex-nowrap justify-start gap-2 sm:gap-3 items-center container pb-[2rem] px-0 sm:pt-0 sm:pb-[20px] text-[#4e4b4a] border-b-[2px] border-solid border-stone-300'>
      <CustomTabs tabsData={tabsData} />
      <div className='flex flex-col justify-center items-center py-[2rem] px-[15px] sm:pt-0 sm:pb-[1.2rem] sm:px-[4rem] text-[#4e4b4a]'>
        <h1 className='text-[1.4rem] md:text-[1.5rem] lg:text-[1.8rem] text-left font-semibold max-w-[100%] lg:max-w-[100%] md:max-w-[90%] sm:text-center mb-[0px]'>
          Our <span className='font-semibold italic text-[#8f8c8b]'>Deep Tech</span> platform has aided in enormous changes.
        </h1>
        <div className='sm:text-center w-full'>
          <p className='inline-block text-[18px] sm:text-[20px] text-left sm:mx-0'>Teams were achieving</p>
          <div className='block sm:inline-block text-left'>
            <h1 className='inline-block text-[40px] sm:text-[46px] md:text-[58px] border-b-[8px] border-solid border-[#fece46] font-extrabold mr-4 sm:mx-4 leading-tight'>2x</h1>
            <p className='inline-block text-[18px] sm:text-[20px]'>the quality at</p>
          </div>
          <div className='block sm:inline-block text-left'>
            <h1 className='inline-block text-[40px] sm:text-[46px] md:text-[58px] border-b-[8px] border-solid border-[#fece46] font-extrabold mr-4 sm:mx-4 leading-tight'>50%</h1>
            <p className='inline-block text-[18px] sm:text-[20px]'>of the time, with</p>
          </div>
          <div className='block sm:inline-block text-left'>
            <h1 className='inline-block text-[40px] sm:text-[46px] md:text-[58px] border-b-[8px] border-solid border-[#fece46] font-extrabold mr-4 sm:mx-4 leading-tight'>1/3</h1>
            <p className='inline-block text-[18px] sm:text-[20px]'>of their resources.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Platform
