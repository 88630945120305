import { configureStore } from '@reduxjs/toolkit';
import navSlice from '../slices/navSlice';
import activePlatformSlice from '../slices/activePlatformSlice';

const store = configureStore({
	reducer: {
		navSlice: navSlice,
		activePlatformSlice: activePlatformSlice
	},
})

export default store;