import React, { useState } from 'react';
import { Button, Fab } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import careerLeftImage from '../assets/images/careerLeftImage.jpeg'
import CloseIcon from '@mui/icons-material/Close';

const Career = () => {

	const [careerActive, setCareerActive] = useState(false);

	const Career = [
		{
			title: 'Interns',
			content: `Looking to do coffee runs and redundant paperwork, or just sit around gawking at your cell phone? Please don’t apply. We are looking for interns who join our company as something more than just a learning experience. We provide them with opportunities to work on real-life projects and hone relevant skills. 
			\n Any creative, fun-loving, passionate and open-minded person who knows he/she is going to be the part of the team, is our fit.`
		},
		{
			title: 'Graduates or Early Career',
			content: `Let us be your initiators when you step into the professional world. We are looking for graduates who are seeking an early career opportunity and maybe if performed well, can be absorbed in the core team. 
			\n Any graduate who is keen to learn and perform at the same time, is thriving to be better in his/her field and is a team player, talk to us.`
		}
	]

	const executives = {

		title: 'Executives',
		persons: [
			{
				title: 'Human Dynamo',
				content: `A Human Dynamo is a person who is a go-getter, not only are they very enterprising in nature but they believe in smart work. An enthusiast who loves to communicate and help their clients to optimize their experience with the products. They help business see exponential growth. Think you are a Human Dynamo, contact us?`
			},
			{
				title: 'Engineers',
				content: `Our engineers bring solutions to life, settle for nothing less, and achieve their goals, no matter what. With unimaginable work-ethic, they provide tangible solutions to the problems and get their work noticed too.`
			},
			{
				title: 'Creatives',
				content: `They paint our venture and communicate our endeavors that go behind the curtain. These people are the designers, the makeup artists of how our company looks like. Care to stroke a brush?`
			}
		]
	}

	return (
		<div id="career" className='mainSection flex flex-wrap flex-col md:flex-nowrap justify-start items-center container md:max-w-[70vw] py-[0rem] sm:px-[0px] text-[#4e4b4a] border-b-[2px] border-solid border-stone-300'>
			{careerActive === true ? 
				<div className='relative px-[15px] lg:px-[30px] md:py-[3rem] lg:py-[6rem] py-[3rem]'>
					<Fab size="small" className='!absolute !right-10 !top-5' color="#ccc" aria-label="cancel" onClick={() => {setCareerActive(false)}}>
						<CloseIcon />
					</Fab>
					<h1 className='text-[1.4rem] md:text-[1.5rem] lg:text-[2rem] font-semibold mb-5 text-left sm:text-center'>
						#JoinOurGrowingCompany
					</h1>
					<div className='md:py-[1rem] lg:py-[0rem] w-full h-auto'>
						<h1 className='text-[1rem] md:text-[1.4rem]  text-justify md:text-center whitespace-pre-line'>
							They are the pillars of our company and the bedrock of our vision, <span className='font-semibold italic text-[#8f8c8b]'>interdependent</span> yet <span className='font-semibold italic text-[#8f8c8b]'>independent</span>.
							<br /> Each team member constantly endeavors to be the better version of themselves and the best version of the company.
						</h1>
						<div className='flex flex-wrap xl:flex-nowrap justify-center items-start mt-4'>
							{Career.map((item, i) => (
								<div className={`w-full ${i === 1 ? 'xl:ml-10 my-2 md:my-0' : '' }`} key={i}>
									<h1 className='text-[1rem] md:text-[20px] text-left md:text-center font-semibold'>{item.title}</h1>
									<p className='leading-normal text-[12px] sm:text-[14px] text-justify mt-2 whitespace-pre-line'>
										{item.content}
										<Button className='capitalize !font-bold' size="small" variant="text" href="mailto:careers@quantis-labs.com" startIcon={<ArrowForwardIcon fontSize='small' />}>Apply</Button>
									</p>
								</div>
							))}
						</div>
						<div className='mt-4'>
							<h1 className='text-[1rem] md:text-xl text-left md:text-center font-semibold'>{executives.title}</h1>
							<div className='flex flex-wrap xl:flex-nowrap justify-center items-start mt-2'>
								{executives.persons.map((item, i) => (
									<div className={`w-full ${i === 1 ? 'xl:mx-10 my-8 md:my-0' : '' }`} key={i}>
										<p className='leading-normal text-[12px] sm:text-[14px] text-justify mt-2 whitespace-pre-line'>{
											(
												<span className={`leading-relaxed`}><strong>{item.title}</strong>: {item.content}</span>
											)
										}
										<Button className='capitalize !font-bold' size="small" variant="text" href="mailto:careers@quantis-labs.com" startIcon={<ArrowForwardIcon fontSize='small' />}>Apply</Button>
										</p>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				:
				<div className='flex justify-center items-center gap-0 sm:gap-0 flex-col min-h-[400px] xl:min-h-[694px]'>
					<div className='min-h-[400px] xl:min-h-[694px] flex justify-center items-center flex-col lg:flex-row gap-0'>
						<div className='min-h-[400px] h-[400px] lg:min-h-[600px] lg:h-[600px]  xl:min-h-[694px] xl:h-[694px] flex-wrap md:flex-nowrap w-full flex items-center justify-around'>
							<img src={careerLeftImage} alt="" title="" className="object-cover w-full h-full"/>
						</div>
						<div className='py-[3rem] lg:py-[1rem] px-[15px] lg:px-[0] lg: w-full'>
							<div className='lg:px-[1rem] w-full flex flex-col justify-center'>
								<h2 className='text-[1rem] sm:text-[1.4rem] font-semibold mb-4 lg:mb-2 leading-[1.3] lg:font-medium'>
									Revolutionising Healthcare with Knowledge-Grounded AI
								</h2>
								<p className='leading-normal mb-0 md:pb-3 text-[12px] sm:text-[14px]'>
									Quantis-AI-Labs is pioneering regulatory-compliant AI to transform digital health. 
									We're assembling an A-team of clinicians, researchers, and innovators to collaborate with top medical institutions.
								</p>
								<p className='leading-normal mb-0 md:pb-3 text-[12px] sm:text-[14px]'>
									Our mission? Leverage knowledge-grounded AI to tackle real-world health challenges. 
									We extract vital insights from fragmented data to get life-saving information to doctors and nurses when they need it most.
								</p>
								<p className='leading-normal mb-0 md:pb-3 text-[12px] sm:text-[14px]'>
									If you share our passion for driving change through advanced AI, join our team! 
									We seek adventurous talent to translate cutting-edge research into improved patient outcomes.
								</p>
								<p className='leading-normal mb-0 md:pb-3 text-[12px] sm:text-[14px]'>
									<span className='font-bold'>
									Together, we're shaping the future of medicine with knowledge-based AI. Let's connect!
									</span>
								</p>
								<div className='w-full flex justify-center items-center mt-10'>
									<Button className='!capitalize !leading-normal !text-[14px] !font-semibold' variant="contained" onClick={() => setCareerActive(true)}>
										#JoinOurGrowingCompany
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	)
}

export default Career;