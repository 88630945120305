import React from 'react' // importing React

// Below is our Component's JSX code. Simple component to render some text that's it.
const Home = () => {
  return (
    <div className="home-landing-section z-[9] !max-w-[100%] px-[15px] h-max text-left w-full 
                        sm:ml-[0px] sm:w-[80%] !sm:max-w-[80%] sm:p-0 
                        md:ml-[0px] !md:max-w-[64%] md:w-[64%] lg:w-[60%] xl:w-[50%]">
        <h1 id="landing-text" className="text-light font-medium text-shadow-title text-[1.4rem] mb-[15px] leading-normal 
                        sm:text-[1.4rem] sm:mb-[15px] sm:leading-[1.3]
                        md:text-[1.6rem] md:font-medium lg:text-[2rem] lg:font-medium xl:text-[2.4rem] lg:mb-5">
            Welcome to Quantis Health, where the future of healthcare innovation is taking shape!
        </h1>
        <p className="w-full sm:leading-6 mb-[15px] text-[16px] text-justify 
                        sm:mb-[20px] sm:text-left lg:text-[18px] lg:font-normal">
            Excitement is building as we prepare to launch a groundbreaking platform that revolutionises the healthcare experience. At Quantis Health, we're dedicated to empowering individuals, enhancing accessibility, and reshaping how we approach well-being. <span className='font-bold'>Stay tuned!</span>
        </p>
        <p className="font-semibold  text-right text-[12px] leading-normal uppercase mb-0 
                        sm:text-[14px] sm:leading-relaxed sm:mb-[20px] xl:text-[1rem]">
            — Redefining Wellness, Empowering Lives!
        </p>
    </div>
  )
}

export default Home
