import React, { useEffect, useState } from 'react'; // Importing react and necessary functions
import MobileMenu from '../components/MobileMenu'; // Importing mobile menu component
import { useSelector, useDispatch } from 'react-redux'; // Importing useSelector and useDispatch hook to integrate redux with this component
import { changeNavState } from '../slices/navSlice'; // Importing navSlices for state in redux
import { Link } from 'react-scroll'; // Importing Link component from react-scroll to create links on click which scrolls to that section
import logo from '../assets/images/QUANTIS AI LABS.png'; // Importing Logo Image
import { Button } from '@mui/material'; // Importing button component from MUI

const menuListArray = ['Home', 'About', 'Our Story', 'Platform', 'Career', 'Contact'] 
// In this code we are defining an array with our menu items as array items to make things easy afterwards like 
// instead of hardcoding these in our code later on we’ll just use map and iterate over this array to show our nav menus.

const Navbar = () => {
	const navState = useSelector((state) => state.navSlice.navState); // Getting navstate value from the store
	const navStateDispatch = useDispatch(); // Initializing our dispatch fucntion for state change in redux
	const [showLogo, setShowLogo] = useState(false);
	// this state is to help show/hide logo based on scroll position
	const [changeNavBg, setchangeNavBg] = useState(false);
	// this state is to change the background color of website based on scroll position and current section

	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	// this state is to keep the record of window width to help make webiste responsive

	useEffect(() => { // this useEffect function is to handle the window resize event and updating screenWidth state accordingly
		const handleResize = () => { // function to update screenWidth state
		  setScreenWidth(window.innerWidth);
		};
	
		window.addEventListener('resize', handleResize); // window resize event with handleResize function as callback which will update state
	}, []);

	window.onscroll = function() { // window on scroll event code
		if (window.innerWidth > 640) { 
			// if window.innerwidth is lower than 640 show the logo in header as the logo from bottom is hidden which appears in  desktop view

			// if document.body.scrollTop is greater than the position where the logo from banner bottom left gets hidden because of scrolling then show the logo in header
			if (document.body.scrollTop > (window.innerHeight * 0.9) || document.documentElement.scrollTop > (0.9 * window.innerHeight)) {setShowLogo(true);} 
			else {setShowLogo(false);} // else set showLogo to false
		} 
		else {setShowLogo(false);} // else set showLogo to false

		if (document.body.scrollTop >= 100 || document.documentElement.scrollTop >= 100) {
			// if document.body.scrollTop is greater than or equal to 100 or document.documentElement.scrollTop is greater than or equal to 100 then 
			// change the state changeNavBg to true which we will use later to change the color of our navbar
			setchangeNavBg(true);
		} else {
			// else set changeNavBg to false to reset its color
			setchangeNavBg(false);
		}
	};

	const updateData = (item) => {
		// Dispatch an action to update the state with the value of nav bar list item which is being clicked
		navStateDispatch(changeNavState(item));
	};

	function capitalizeEveryWord(str) {
		// function to capitalize word 
		return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
	}

	useEffect(() => {
		// useEffect to handle the change of navbar list item with change of section while scrolling
		const handleScroll = () => {
			clearTimeout(scrollTimeout);

			scrollTimeout = setTimeout(() => {

				// Get all the sections on the page
				const sections = document.querySelectorAll('.mainSection');
			
				// Find the active section based on its position on the page
				let currentSection = '';
				sections.forEach((section) => {
					const rect = section.getBoundingClientRect();
					if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
						var sectionString = section.id.replace(/-/g, ' ');
						currentSection = capitalizeEveryWord(sectionString);
					}
				});
			
				// Update the state with the ID of the active section
				updateData(currentSection);
			}, 40);
		};

		let scrollTimeout;
	
		// Attach the scroll event listener
		window.addEventListener('scroll', handleScroll);
	}, []);

	// Then we have our tradition react jsx code and as you can see menuListArray is used to render all of our menus with less code
	// All the functionalities described above is implemented in below code including dynamically changing classes on the basis of state value
	return (
		<nav className={`fixed left-0 top-0 h-[60px] z-[99999] w-screen nav-dark transition-all duration-900 ${changeNavBg? 'bg-[#111]/60' : 'bg-transparent'} text-right`}>
			<div className={`w-full h-full flex ${screenWidth <= 640 ? 'justify-between' : showLogo ? 'justify-between' : 'justify-end'} items-center lg:px-[30px] sm:px-[15px]`}>
				{screenWidth <= 640 ?
					<a className="z-[99999] h-[40px] w-[40px] ml-[15px] sm:ml-[10px]" href="/">
						<img className={`nav-logo dark relative h-full w-full z-[99999] shadow-lg rounded-full`} src={logo} alt="Quantis AI Labs" />
					</a>
					:
					showLogo && 
						<a className="z-[99999] h-[40px] w-[40px]" href="/">
							<img className={`nav-logo dark relative h-full w-full z-[99999] shadow-lg rounded-full`} src={logo} alt="Quantis AI Labs" />
						</a>
				}
				<div className='flex justify-center items-center'>
					<ul className={`nav-menu list-none flex items-center justify-center text-sm text-right ${changeNavBg? '' : 'text-white'} text-white hidden sm:block md:block lg:block `}>
						{menuListArray.map((item, i) => {
							return (
								<li key={item} className={`nav-menu-item nav-cta hide-tablet-and-mobile 
									inline-block text-[#fff] font-normal cursor-pointer mx-1 px-3 rounded-lg 
									${i === menuListArray.length - 1 ? 'ml-3 mr-0 pr-0' : ''}
									${navState === item && navState !== 'Contact' ? 'active !text-[#0558f6] bg-[#fff]' : ''} 
									`}>
									<Link onClick={() => {updateData(item)}} className="nav-link text-[12px] font-semibold" role="button" to={item.toLowerCase().replace(/\s+/g, '-')} offset={-60} smooth={true} duration={400}>
										{item === 'Contact' ? 
											<Button className='capitalize !pt-[6px] !pb-[5px] !leading-none !text-[12px] !font-semibold' variant="contained">{item}</Button>
											:
											item
										}
									</Link>
								</li>
							)
						})}
					</ul>
					<MobileMenu changeNavBg={changeNavBg} />
				</div>
			</div>
		</nav>
	)
}

export default Navbar;