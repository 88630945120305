import React, { useState, useEffect } from 'react' // Importing React and necessary functions
import CustomSlider from '../components/CustomSlider'; // This component is a custom slider component. Passing an array of multiple elements or component will render a slider.
import sectionImage from '../assets/images/aboutSection.jpeg' // Importing section image

const AboutContent = ({h2Data, pData}) => {
  return (
    <div className='p-[0] lg:p-0 mb-4 md:p-0 m-auto'>
      <h2 className='text-[1.2rem] sm:text-[1.4rem] font-semibold mb-4 lg:mb-2 leading-[1.3] lg:font-medium'>
        {h2Data}
      </h2>
      <p className='text-[12px] lg:font-normal lg:text-[14px]'>
        {pData}
      </p>
    </div>
  )
}

const slides = [
  <AboutContent 
    h2Data={'Health data freedom. Taking back control.'} 
    pData={'This drove us to launch QUANTIS LABS - a secured and integrated lifetime health profile platform owned by you.'} 
  />,
  <AboutContent 
    h2Data={'The Health Data Trap'} 
    pData={`Critical health data trapped in fragmented medical records, overflowing filing cabinets and disjointed apps is failing people and progress.
  Doctors make decisions blindfolded. Diseases slip through the cracks. New cures stall. People stay symptoms-driven, not root cause oriented.
  The time has come to break open the health data trap.`}
  />,
  <AboutContent 
    h2Data={'The QUANTIS LABS Freedom Platform'} 
      pData={`We are building an encrypted vault for your lifetime health data - unified, secure and shareable on your terms.
  Plug in your wearables, medical records, genetic maps and more. Health AI generates your customized insights. Enable better interventions and wellness via comprehensive data sharing.`}
  />,
  <AboutContent 
    h2Data={'The Future We Seek'} 
      pData={`A future where people have complete control over their health stories - enabling better health outcomes, preventive medical algorithms, early disease detection through holistic profiles and participatory advancement of healthcare.
  Join the leading edge now!`}
  />,
];

const About = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <div id="about" className={`mainSection z-[99] container w-full min-h-[60vh] pt-[0rem] pb-[1rem] lg:py-[6rem] lg:px-[15px] lg:px-[30px] flex flex-wrap lg:flex-nowrap justify-between items-center gap-8 ${screenWidth >= 1024 ? 'text-[#4e4b4a]' : 'text-[#4e4b4a]'}`}>
      <div className='w-full flex items-center justify-center self-stretch'>
        {screenWidth >= 1024 ?
          <>
            <div className='p-[2rem] lg:p-0 md:p-[1rem] w-[62%]'>
              {
                slides.map((item, i) => (
                  <React.Fragment key={i}>
                    {item}
                  </React.Fragment>
                ))
              }
            </div>
            <div className='w-full h-full'></div>
          </>
          :
          <img id="l-img" 
            data-src="" 
            src={sectionImage} 
            className="w-full max-w-full block" alt="section emerging technologies" title="" 
          />
        }
      </div>
      {screenWidth < 1024 &&
        <div className='px-[15px] py-[0rem] w-full'>
          {
            slides.map((item, i) => (
              <React.Fragment key={i}>
                {item}
              </React.Fragment>
            ))
          }
        </div>
      }
    </div>
  )
}

export default About