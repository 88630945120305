import React from 'react'; // Importing react
import BannerBgVideo from './BannerBgVideo'; // Importing banner background video Component which will render banner's background video
import Home from '../pages/Home' // importing Home component which renders content of Home / Banner
// import { useSelector } from 'react-redux';
import LogoContent from './LogoContent'; // Importing logo Content Component which renders logo and circle graphic which is shown in our banner.

// Below is our banner component. nothing fancy here just typical wrapper component with more important child components
const Banner = () => {
	// const navState = useSelector((state) => state.navSlice.navState);

	return (
		<div id="home" className="mainSection relative w-screen h-screen w-[100svw] h-[100svh] overflow-hidden">
			<div name='home' className='header-section text-white h-screen h-[100svh] flex justify-center items-center relative'>
				<BannerBgVideo />
				<Home />
			</div>
			<LogoContent />
		</div>
	)
}

export default Banner