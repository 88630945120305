import React, {useState} from 'react'; // Importing react and necessary functions
import styled from 'styled-components'; // Importing styled components
import { animated, useSpring, useTrail, a } from '@react-spring/web' // Importing react spring functions for animation
import { useSelector, useDispatch } from 'react-redux'; // Importing useSelector and useDispatch hook to integrate redux with this component
import { changeNavState } from '../slices/navSlice'; // Importing navSlices for state in redux
import { Link } from 'react-scroll'; // Importing Link component from react-scroll to create links on click which scrolls to that section

// This is styled component stuff to style our hamburger menu toggler
const Toggler = styled.div`
	z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
	padding: 7.5px;
	cursor: pointer;
	> span {
		width: 20px;
		height: 2px;
		margin-bottom: 0.28rem;
		transition: all 0.3s ease-in-out;
		border-radius: 10px;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		-ms-border-radius: 10px;
		-o-border-radius: 10px;
	}
	> span:last-child {
		margin: 0;
	}
	&.active span:first-child {
		transform: translateY(6.4px) rotate(45deg);
		-webkit-transform: translateY(6.4px) rotate(45deg);
		-moz-transform: translateY(6.4px) rotate(45deg);
		-ms-transform: translateY(6.4px) rotate(45deg);
		-o-transform: translateY(6.4px) rotate(45deg);
	}
	&.active span:nth-of-type(2) {
    	width: 0;
	}
	&.active span:last-child {
		transform: translateY(-6.4px) rotate(-45deg);
		-webkit-transform: translateY(-6.4px) rotate(-45deg);
		-moz-transform: translateY(-6.4px) rotate(-45deg);
		-ms-transform: translateY(-6.4px) rotate(-45deg);
		-o-transform: translateY(-6.4px) rotate(-45deg);
	}
`

// This is styled component stuff to style our hamburger menu toggler
const liStyle = `w-max nav-menu-item nav-cta hide-tablet-and-mobile text-[1.5rem] leading-normal inline-block text-[#fff] font-bold 
				cursor-pointer mx-0 sm:leading-[2.5rem] sm:text-xs origin-right sm:mx-4 [will-change:transform,opacity] `

// This is style object for mobile menu li labels. Again an array of all the menus to make our life easier.
const menuListArray = ['Home', 'About', 'Our Story', 'Platform', 'Career', 'Contact']

const MobileMenu = (props) => {

	const navState = useSelector((state) => state.navSlice.navState); // Getting navstate value from the store
	const navStateDispatch = useDispatch(); // Initializing our dispatch fucntion for state change in redux

	const [menuState, setMenuState] = useState(false); // state to monitor if the menu is active or not by using true or false value
	const [renderItems, setRenderItems] = useState(false); // state to tell program to render items as soon as the menu drawer is opened

	// Below is the use spring animation code to animate drawer
	const spring = useSpring({
		config: { mass: 5, tension: 2000, friction: 200 },
		left: menuState ? '0%' : '100%',
		onRest: () => {
			if (!menuState) {
				setRenderItems(false)
			} else {
				setRenderItems(true)
			}
		},
		delay: renderItems ? 1200 : 0,
		from: {left: '100%'}
	})

	// Below is the use spring animation code to animate menu items
	const trail = useTrail(menuListArray.length, {
		config: { mass: 10, tension: 1800, friction: 200 },
		opacity: renderItems ? 1 : 0.5,
		transform: renderItems ? 'rotateY(0deg) rotateZ(0deg)' : 'rotateY(90deg) rotateZ(-20deg)',
		delay: menuState ? 0 : 300,
		onRest: () => {
			if(!renderItems) {
				setMenuState(false);
			}
		},
		from: { opacity: 0.5, transform: 'rotateY(90deg) rotateZ(-20deg)' },
		to: { opacity: renderItems ? 1 : 0.5, transform: renderItems ? 'rotateY(0deg) rotateZ(0deg)' : 'rotateY(90deg) rotateZ(-20deg)' },
	})

	// Click handler for hamburger menu
	const handleClick = () => {
		if (menuState) {
			setRenderItems(false)
		} else {
			setMenuState(true);
		}
	}

	// Click handler for navbar items
	const updateData = (item) => {
		// Dispatch an action to update the data
		navStateDispatch(changeNavState(item));
		handleClick();
	};

	// Then we have our tradition react jsx code and as you can see menuListArray is used to render all of our menus with less code
	// All the functionalities described above is implemented in below code including dynamically changing classes on the basis of state value
	return (
		<div className={`relative flex items-center justify-center bg-transparent sm:hidden mr-[7.5px] sm:mr-0`} >
			<Toggler onClick={handleClick} className={`${menuState ? 'active': ''} z-[99999]`}>
				<span className={`bg-white`}></span>
				<span className={`bg-white`}></span>
				<span className={`bg-white`}></span>
			</Toggler>
			<animated.ul className={`fixed top-0 pt-10 px-2 h-max rounded-none w-full pb-3 sm:p-4 text-center flex flex-col 
					justify-end items-end z-[99] bg-gradient-to-bl from-zinc-900`}
					style={spring}
				>
				{trail.map(({ height, ...style }, index) => (
					<Link 
						key={index} 
						onClick={() => {updateData(menuListArray[index])}} 
						className={liStyle + `${navState === menuListArray[index] ? '!text-[#0558f6]' : ''}`} 
						style={style}
						role="button" 
						to={menuListArray[index].toLowerCase().replace(/\s+/g, '-')} 
						offset={-60} 
						smooth={true} duration={400}
						>
						{menuListArray[index]}
					</Link>
				))}
			</animated.ul>
		</div>
	)
}

export default MobileMenu;