import React from 'react'
import ourStory from '../assets/images/OurStoryImage.png';

const OurStory = () => {

  return (
    <div id="our-story" className='mainSection min-h-[60vh] flex flex-wrap gap-0 lg:flex-nowrap justify-between items-stretch container py-[0rem] text-[#4e4b4a] border-b-[2px] border-solid border-stone-300'>
      <div className='flex-wrap md:flex-nowrap w-full flex items-center justify-around'>
        <img src={ourStory} alt="" title="" className="h-full w-full block object-cover"/>
			</div>
			<div className='py-[2rem] sm:py-[3rem] lg:py-[0rem] w-full flex justify-center items-center overflow-y-auto'>
        <div className='flex flex-col justify-center items-center px-[15px] sm:px-[15px] lg:px-[30px]'>
          <p className='leading-normal mb-0 md:pb-3 text-[12px] sm:text-[14px]'>
            Quantis Labs was founded on the belief that healthcare should empower people to live their best lives.
            As technologists and healthcare professionals, we had witnessed firsthand how the system was failing patients. 
            Medical decisions were being made in silos without context of a person's unique health history, lifestyle, and goals. 
            Data wasn't integrated. Providers didn't collaborate. Patients didn't have agency. 
          </p>
          <p className='leading-normal mb-0 md:pb-3 text-[12px] sm:text-[14px]'>
            We envisioned something better - a healthcare ecosystem centered around the individual. 
            One that uses data responsibly to help people prevent illness, achieve optimal wellbeing, and navigate life's ups and downs with grace.
          </p>
          <p className='leading-normal mb-0 md:pb-3 text-[12px] sm:text-[14px]'>
            Our mission is to realize this vision by building technology infrastructure and services that connect patients, providers, and partners. 
            We architect responsive systems that adapt to each person's changing needs over time. And we foster collaborative relationships anchored in compassion.
          </p>
          <p className='leading-normal mb-0 md:pb-3 text-[12px] sm:text-[14px]'>
            At the core, Quantis Labs develops platforms that empower people with agency over their health. 
            We create meaningful ways for individuals to contribute to and benefit from the healthcare process. 
            And we enable secure data sharing so providers can gain fuller context and deliver personalised care. 
          </p>
          <p className='leading-normal mb-0 md:pb-3 text-[12px] sm:text-[14px]'>
            The road ahead requires reimagining entrenched systems, culture, and technology together in service of people. 
            It won't be easy. But the opportunities for positive impact make it profoundly worth pursuing. 
          </p>
          <p className='leading-normal mb-0 md:pb-3 text-[12px] sm:text-[14px]'>
            <span className='font-bold'>
              We're on this journey for the long haul - grounded in hope, conviction, and readiness to bridge the exponential possibilities ahead.
            </span>
          </p>
        </div>
			</div>
		</div>
  )
}

export default OurStory
