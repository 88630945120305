import React from 'react'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

const CustomSlider = ({slides}) => {

  var settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    draggable: true,
    swipeToSlide: true,
    touchMove: true,
    dotsClass: 'dots-wrapper-ul px-4 w-max m-auto',
    customPaging: (i) => {
      return (
        <div
          className={`dot`}
          key={i}
        ></div>
      )
    },
  };

  return (
    <>
      <style>{`
        .dots-wrapper-ul li {
          display: inline-block;
          list-style: none;
          transition: all 0.3s ease;
        }
        .dot {
          width: 6px;
          height: 6px;
          background-color: #fff;
          border-radius: 5px;
          margin: 0 5px;
          cursor: pointer;
        }

        .dots-wrapper-ul li .dot {
          margin-left: 0;
          background-color: #000;
        }

        .dots-wrapper-ul li:first-of-type .dot {
          margin-left: 0;
        }

        .dots-wrapper-ul li.slick-active .dot {
          background-color: #0558f6;
        }
        
      `}</style>
      <Slider className="w-full h-full" {...settings} >
        {
          slides.map((item, i) => (
            <div className="w-full h-full" key={i}>
              {item}
            </div>
          ))
        }
      </Slider>
    </>
    
  )

}

export default CustomSlider
