import { createSlice } from '@reduxjs/toolkit';

export const activePlatformSlice = createSlice({
	name: 'activePlatform',
	initialState: {
		activePlatform: 0,
	},
	reducers: {
		changeActivePlatformState: {
			reducer: (state, action) => {
				state.activePlatform = action.payload
			},
			prepare: (text) => {
				return {
					payload: text
				}
			}
		}
	}
})

// each case under reducers becomes an action
export const { changeActivePlatformState } = activePlatformSlice.actions

export default activePlatformSlice.reducer