import React from 'react'
import ContactForm from '../components/ContactForm'

const Contact = () => {
  return (
    <div name='contact' id="contact" className='mainSection min-h-[65vh] md:pt-0 flex flex-wrap flex-col-reverse sm:flex-col-reverse md:flex-row md:flex-nowrap justify-between items-center w-full text-[#4e4b4a] border-b-[2px] border-solid border-[#efedec] mb-0 sm:!mb-[60px] lg:!mb-[39px]'>
			<div className='relative w-full h-auto pb-[33%] pt-5 min-h-[600px] xl:min-h-[692px]'>
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d100940.92231836342!2d-122.43485684772477!3d37.75712844597276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80859a6d00690021%3A0x4a501367f076adff!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2sin!4v1706949447259!5m2!1sen!2sin" 
					width="600" 
					height="480"
					allowFullScreen="" 
					loading="lazy" 
					referrerPolicy="no-referrer-when-downgrade"
					className='absolute top-0 left-0 w-full h-full'
					title='quantis-on-map'
				>
				</iframe>
			</div>
      <ContactForm />
	</div>
  )
}

export default Contact
