import React from 'react'; // importing react
import video from '../assets/videos/Quantis Health Video 1.mp4'; // importing Background video from our static folder

// Below is our component's jsx code which simply provides a video element and an overlapping element with bg-[#393736]
const BannerBgVideo = () => {
	return (
		<div className='bg-video'>
			<video className="hide-mobile-only absolute top-0 left-0 h-full w-screen w-[100svw] bg-cover object-cover" autoPlay="autoplay" loop="loop" muted playsInline>
				<source className='h-full w-full' src={video} type="video/mp4" />
			</video>
			<span className="absolute top-0 left-0 z-[2] bg-[#393736] h-full w-screen w-[100svw] opacity-60"></span>
		</div>
	)
}

export default BannerBgVideo;