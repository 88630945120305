import './App.css'; // this is our app.css for styles applied from one point to ignore code repeatability
import Banner from './components/Banner'; // Banner component is basically our header component. Banner is just an alias Name.
import Navbar from './components/Navbar'; // Navbar component
import About from './pages/About'; // About section component
import OurStory from './pages/OurStory'; // Our story Section component
import Platform from './pages/Platform'; // Platform section component
import Contact from './pages/Contact'; // Contact section component
import Footer from './components/Footer'; // Footer section component
import Career from './pages/Career'; // Career section component

function App() {
  return (
    <div className="relative overflow-x-hidden">
      <Navbar />
      <Banner />
      <About />
      <OurStory />
      <Platform />
      <Career />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
