/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react"; // Importing react
import logo from '../assets/images/QUANTIS AI LABS.png'; // importing logo image from static assets folder
// import logo2 from '../assets/images/Quantis Health.png'

// Below is our component's jsx code. Different classes is to make the circle with logo image look similar in most of the big screens.
// but in mobile screens the logo image and circle is hidden and only the text is visible on the footer of the banner
const LogoContent = () => {
    return (
        <div name='logoContent' className='flex justify-center items-center absolute bottom-0 z-[99] gap-0 
                sm:w-max sm:-bottom-[60px] sm:-left-[240px] sm:gap-[30px] 
                md:gap-[45px] md:-bottom-[100px] md:-left-[350px]
                lg:gap-[10px] lg:-bottom-[140px] lg:-left-[410px] lg:gap-[30px]
                xl:gap-[30px] xl:-left-[42%]'>
            <div className="bg-transparent border-[#fff] border-0 w-0 h-0 rounded-full flex justify-end items-start 
                    sm:border-2 sm:border-solid sm:w-[300px] sm:h-[300px] sm:relative 
                    md:w-[440px] md:h-[440px] md:w-[440px] md:h-[440px]
                    lg:w-[500px] lg:h-[500px] lg:w-[500px] lg:h-[500px]
                    xl:w-[50vw] xl:h-[75vh] xl:w-[50svw] xl:h-[75svh]">
                <div className="bg-white rounded-full fixed flex justify-center items-center p-[2px] hidden
                        sm:block sm:p-2 sm:h-[60px] sm:w-[60px] sm:top-[45%] sm:absolute sm:-right-[9%] sm:left-auto 
                        md:top-[46%] md:-right-[9%] md:h-[80px] md:w-[80px]
                        lg:-right-[7.5%] lg:h-[80px] lg:w-[80px]
                        xl:-right-[6.5%] xl:h-[100px] xl:w-[100px]">
                    <a className="z-[99999]" href="/">
                        <img className={`nav-logo dark relative h-full w-full z-[99999] shadow-lg rounded-full`} src={logo} alt="Quantis AI Labs" />
                    </a>
                </div>
            </div>
            <div className="flex justify-end items-start 
                sm:flex-1 sm:max-w-[calc(100vw-100px)] sm:max-w-[calc(100svw-100px)]
                md:max-w-[calc(100vw-185px)] md:max-w-[calc(100svw-185px)]">
                <div className="bg-transparent rounded-lg p-[15px] pb-0 text-left text-white
                    sm:p-2 sm:pl-0 sm:mt-[3rem] md:mt-[10rem] xl:mt-[12rem]">
                    <h3 className="text-[11px] sm:text-sm font-bold mb-2">Transforming Healthcare, Redefining Your Well-being</h3>
                    <p className="text-[10px] sm:text-xs mb-4">
                        // We architect healthcare's “central nervous system”, attacking fragmentation to intercept emerging risks early and save thousands of lives yearly //
                    </p>
                </div>
            </div>
        </div>
    )
}

export default LogoContent;