import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeActivePlatformState } from '../slices/activePlatformSlice';

const CustomTabs = ({tabsData}) => {
    const [activeDirection, setActiveDirection] = useState(true);
    const activePlatform = useSelector((state) => state.activePlatformSlice.activePlatform);
    const activePlatformDispatch = useDispatch();

    const updateActivePlatform = (item) => {

        const curentImgTab = document.getElementById(`tabImage${activePlatform}`);
        const currentContentTab = document.getElementById(`tabContent${activePlatform}`);

        const upcomingImgTab = document.getElementById(`tabImage${item}`);
        const upcomingContentTab = document.getElementById(`tabContent${item}`);

        curentImgTab.style.top = `100%`;
        currentContentTab.style.bottom = `100%`;
        upcomingImgTab.style.top = `0`;
        upcomingContentTab.style.bottom = '0';
        curentImgTab.style.transition = "all 0.5s";
        currentContentTab.style.transition = "all 0.5s";
        upcomingImgTab.style.transition = "all 0.5s";
        upcomingContentTab.style.transition = "all 0.5s";
        
		// Dispatch an action to update the data
		activePlatformDispatch(changeActivePlatformState(item));
        setActiveDirection(!activeDirection);
        handleActiveTabIndicator(item);
	};

    const handleActiveTabIndicator = (elementIndex) => {
        const parentTabElement = document.getElementById('tabsHeader');
        const tab = document.getElementById(Object.keys(tabsData)[elementIndex].replace(/\s+/g, '-'));

        const parentArea = parentTabElement.getBoundingClientRect();
        const area = tab.getBoundingClientRect();

        var relativeRect = {
            top: area.top - parentArea.top,
            right: area.right - parentArea.left,
            bottom: area.bottom - parentArea.top,
            left: area.left - parentArea.left,
            width: area.width,
            height: area.height,
        }

        const activeTabIndicator = document.querySelector('.activeTabIndicator');
        if (activeTabIndicator) {
            const left = relativeRect.left;
            activeTabIndicator.style.width = `${relativeRect.width}px`;
            activeTabIndicator.style.bottom = `-1px`;
            activeTabIndicator.style.left = `${left}px`;
            activeTabIndicator.style.transition = "all 0.5s";
        }
	}

    useEffect(() => {
        handleActiveTabIndicator(activePlatform);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='w-full h-full flex flex-col items-center justify-center gap-2'>
            <div id='tabsHeader' className='tabsHeader relative w-[calc(100%-30px)] justify-between mx-[15px] sm:mx-0 sm:w-max flex items-center sm:justify-center'>
                {Object.keys(tabsData).map((element, i) => {
                    return (
                        <Fragment key={element}>
                            <button key={element} id={element.replace(/\s+/g, '-')} className='py-1.5 px-4 sm:px-8 relative text-xs' onClick={() => updateActivePlatform(i)}>
                                {element}
                            </button>
                            {i === 0 && 
                                <span className={`activeTabIndicator absolute z-[-1] transition-all duration-300 h-[4px] bottom-[-1px] bg-[#000]/20`}>
                                </span>
                            }
                        </Fragment>
                    )
                })}
                <span className={`w-full h-[2px] bottom-0 left-0 absolute z-[-1] transition-all duration-300 bg-gradient-to-r from-cyan-500/60 to-blue-500/60 to-indigo-500/60 to-purple-500/60 to-pink-500/60`}>
                </span>
            </div>
            <div className='tabsContainer w-full h-[calc(100vh-280px)] max-h-max overflow-hidden'>
                <div className={`w-full h-full relative tabs-inner-container flex flex-col md:flex-row`}>
                    {
                        Object.values(tabsData).map((item, i) => {
                            return (
                                <Fragment key={i}>
                                    <div id={`tabImage${i}`} className={`tabImage${i} w-full hidden md:block md:w-[50%] h-full absolute overflow-hidden top-0 ${activePlatform === i ? 'z-[99] opacity-100' : 'z-[9] opacity-0'} transition-all duration-150 ${i % 2 === 0 ? 'left-0' : 'right-0'}`}>
                                        <img className='tabImage-img object-cover w-full h-full' src={item['image']} alt="tab showcasing"></img>
                                    </div>
                                    <div id={`tabContent${i}`} className={`tabContent${i} w-full md:w-[50%] h-full absolute top-0 p-0 py-0 ${activePlatform === i ? 'z-[99] opacity-100' : 'z-[9] opacity-0'} bg-white transition-all duration-150 ${i % 2 === 0 ? 'right-0 sm:pl-2' : 'left-0 sm:pr-2'}`}>
                                        <div className='tabContent-data w-full h-full flex justify-center items-start xl:items-center  overflow-y-auto'>
                                            {item['data']}
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default CustomTabs
